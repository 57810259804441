<template>
 <h3 class="text-5xl h-fit">
    <slot></slot>
 </h3>
</template>

<script>
export default {
  name: "Header4Component",
  data: function () {
    return {

    };
  }
};
</script>
