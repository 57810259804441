<template>
    <div class="mx-auto w-full h-auto text-white flex justify-center items-center">
        <slot></slot>
    </div>
</template>

<script>
export default {
    name: "OurBenefitsCardComponent",
};
</script>
