<template>
 <h2 class="h-fit text-6xl w-full">
    <slot></slot>
 </h2>
</template>

<script>
export default {
  name: "Header2Component",
  data: function () {
    return {

    };
  }
};
</script>
