<template>
 <h3>
    <slot></slot>
 </h3>
</template>

<script>
export default {
  name: "Header3Component",
  data: function () {
    return {

    };
  }
};
</script>
