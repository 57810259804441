<template>
    <div>
        <slot></slot>
    </div>
</template>

<script>
export default {
    name: "TechnologiesPageTechnologiesItemComponent",
    data: function () {
        return {

        }
    }
}
</script>