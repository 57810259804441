<template>
 <div class="h-fit text-lg max-w-xl">
    <slot></slot>
 </div>
</template>
<script>
export default {
  name: "BodyText",
  data: function () {
    return {

    };
  }
};
</script>
