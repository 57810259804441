<template>
    <slot></slot>
</template>

<script>
export default {
    name: "OurTeamTeamMemberComponent",
    data: function () {
        return {

        }
    }
}
</script>