<template>
     <v-card class="">
        <slot></slot>
    </v-card>
</template>

<script> 
export default {
    name: "CareersPageComponent"
}
</script>