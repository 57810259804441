<template>
    <div class="w-full xl:h-56 lg:h-44 md:h-auto sm:h-auto xs:h-auto xxs:h-auto hiring-technologies_card text-white flex justify-center items-center">
        <slot></slot>
    </div>
</template>

<script>
export default {
    name: "HiringTechnologiesCardComponent",
};
</script>

<style>

</style>
