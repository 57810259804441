<template>
    <div class="w-full h-auto md:h-80 sm:h-auto xs:h-auto xxs:h-auto flex justify-center text-black flex-col gap-10 1xl:px-44 xl:px-20 lg:px-20 py-60 sm:pt-20 sm:pb-10 xs:pt-10 xs:pb-0 xxs:pt-10 xxs:pb-0">
       <slot></slot> 
    </div>
</template>

<script>
export default {
    name: "WhyUsCardComponent",
    data: function () {
        return {
           
        }
    }
};

</script>

<style>
</style>
