<template>
 <h1 class="h-fit text-6xl max-w-xl">
    <slot></slot>
 </h1>
</template>

<script>
export default {
  name: "Header1Component",
  data: function () {
    return {

    };
  }
};
</script>
