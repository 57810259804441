<template>
 <div class="h-fit text-xl lg:max-w-xl md:w-auto"> 
    <slot></slot>
 </div>
</template>
<script>
export default {
  name: "BodyText4",
  data: function () {
    return {

    };
  }
};
</script>
