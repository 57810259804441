<template>
  <div class="root">
    <router-view />
  </div>
</template>

<script>
// import Header from "./components/layout/header";
// import Footer from "./components/layout/footer";
// import Loader from "./components/loader";

export default {
  name: "App",
  components: {
    // Header,
    // Footer,
    // Loader,
  },
  data() {
    return {
    };
  },

};
</script>
